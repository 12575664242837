import {
    AppBar,
    Button,
    ButtonGroup,
    Divider,
    Menu,
    MenuItem,
    MenuProps,
    Toolbar,
    withStyles,
} from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import { intlMessages, setLocale, t } from "../../i18n/util";
import { CDN_URL } from "../../config";
import { authStore } from "../../stores/AuthStore";
import { generalStore } from "../../stores/GeneralStore";
import { history } from "../routers/history";
import { Routes } from "../routers/Routes";
import { Icon } from "../util/Icon";
import { ImageLogo, GeniomedLogo, OptimizerLogo } from "../util/Images";
import { newsStore } from "../../stores/NewsStore";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { ILocales } from "../../i18n/ILocales";
import { API } from "../../network/API";
import { optimizerStore } from "../../stores/OptimizerStore";

const StyledToolbar = withStyles({
    root: {
        display: "flex",
        justifyContent: "space-between",
    },
})(Toolbar);

const StyledMenu = withStyles({
    paper: {
        minWidth: 185,
        boxShadow: "0 -2px 10px 0 rgba(124, 126, 136, 0.1), 0 8px 10px 0 rgba(101, 121, 222, 0.05)",
    },
    list: {
        padding: 0,
    },
})((props: MenuProps) => (
    <Menu
        keepMounted
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles({
    root: {
        height: 56,
        padding: "6px 16px",
        display: "flex",
        justifyContent: "space-between",
    },
})(MenuItem);

const NavigationSection = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
    justify-content: ${({ justify }: { justify?: string }) => justify};
`;

const CenterContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledImageLogo = styled(ImageLogo)`
    height: 28px;
    margin-right: 40px;
`;

const StyledGeniomedLogo = styled(GeniomedLogo)`
    height: 28px;
    margin-right: 20px;
`;

const StyledOptimizerLogo = styled(OptimizerLogo)`
    height: 28px;
    margin-right: 40px;
`;

const MenuText = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

const MenuLink = styled.a`
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: inherit;
`;

const NotificationContainer = styled.span`
    position: relative;
    display: flex;
`;

const NotificationDot = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    position: absolute;
    top: 0;
    right: 0;
`;

const Disclaimer = styled.p`
    padding: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    :hover {
        overflow: visible;
        white-space: normal;
    }
`;

const CEMark = styled.img`
    height: 18px;
    margin: 7px 0;
`;

type IProps = {
    leftComponent: React.ReactNode;
};

export const UserNavigation = observer(({ leftComponent }: IProps) => {
    const [userMenuAnchorElement, setUserMenuAnchorElement] = React.useState<null | HTMLElement>(null);
    const [helpMenuAnchorElement, setHelpMenuAnchorElement] = React.useState<null | HTMLElement>(null);
    const ifuPath = `${CDN_URL}/ifus/geniomed/${authStore.isDoctor ? "doctor" : "patient"}_${generalStore.locale}.pdf`

    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setUserMenuAnchorElement(event.currentTarget);
    };

    const handleClickHelp = (event: React.MouseEvent<HTMLButtonElement>) => {
        setHelpMenuAnchorElement(event.currentTarget);
    };

    const handleClickNotification = () => {
        history.push(Routes.DOCTOR.NEWS);
    };

    const handleClose = () => {
        setUserMenuAnchorElement(null);
        setHelpMenuAnchorElement(null);
    };

    const handleLogout = () => {
        handleClose();
        generalStore.isLoading = true;
        authStore.logout();
        history.push(Routes.ROOT);
        generalStore.isLoading = false;
    };

    const changeLanguage = async (locale: ILocales) => {
        await API.setLanguage(locale);
        setLocale(locale);
    };

    const isCurrentLanguage = (locale: ILocales) => {
        return generalStore.locale === locale;
    };

    React.useEffect(() => {
        newsStore.load();
    }, []);

    return (
        <>
            <AppBar color="inherit" style={{ overflow: "auto" }}>
                <StyledToolbar>
                    {authStore.isPatient ? (
                        <NavigationSection justify="left">
                            <CenterContainer>
                                <StyledImageLogo />
                                {leftComponent}
                            </CenterContainer>
                        </NavigationSection>
                    ) : (
                        <>
                            <NavigationSection>{leftComponent}</NavigationSection>
                            <NavigationSection justify="center">
                                <StyledGeniomedLogo />
                                <StyledOptimizerLogo />
                            </NavigationSection>
                        </>
                    )}
                    <NavigationSection justify="flex-end">
                        <div style={{ display: "inline-block", marginRight: 19 }}>
                            <Button
                                data-id="navigation_user_menu_toggle"
                                aria-controls="user-menu"
                                aria-haspopup="true"
                                disableRipple
                                onClick={handleClickMenu}
                            >
                                <span style={{ color: "#1E1E1E", fontWeight: "normal", whiteSpace: "nowrap" }}>
                                    {authStore.userDisplayName}
                                </span>{" "}
                                <Icon name="dropdown" color="currentColor" />
                            </Button>
                            <StyledMenu
                                id="user-menu"
                                PaperProps={{ "data-id": "navigation_user_menu" } as any}
                                anchorEl={userMenuAnchorElement}
                                open={!!userMenuAnchorElement}
                                onClose={handleClose}
                                getContentAnchorEl={null}
                            >
                                <StyledMenuItem data-id="logout" onClick={handleLogout}>
                                    <MenuText>{t("navigation.logout.label")}</MenuText>
                                </StyledMenuItem>
                            </StyledMenu>
                        </div>

                        <ButtonGroup variant="text">
                            {(Object.keys(intlMessages) as ILocales[]).map(lang => (
                                <Button
                                    key={`${lang}`}
                                    disabled={isCurrentLanguage(lang)}
                                    onClick={() => changeLanguage(lang)}
                                >
                                    {`${lang}`.toUpperCase()}
                                </Button>
                            ))}
                        </ButtonGroup>

                        {authStore.isDoctor ? (
                            <Button onClick={handleClickNotification} data-id="navigation_notification">
                                <NotificationContainer>
                                    {newsStore.hasUnreadNews && <NotificationDot />}
                                    <NotificationsNoneIcon />
                                </NotificationContainer>
                            </Button>
                        ) : null}

                        <Button data-id="navigation_help" disableRipple size="small" onClick={handleClickHelp}>
                            <Icon name="help" color="currentColor" style={{ marginRight: 8 }} />
                            {t("navigation.help.label")}
                        </Button>
                        <StyledMenu
                            id="help-menu"
                            PaperProps={{ "data-id": "navigation_help_menu" } as any}
                            anchorEl={helpMenuAnchorElement}
                            open={!!helpMenuAnchorElement}
                            onClose={handleClose}
                            getContentAnchorEl={null}
                        >
                            <StyledMenuItem data-id="manual" onClick={handleClose}>
                                <MenuLink
                                    href={ifuPath}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <MenuText>{t("navigation.help.manual")}</MenuText>
                                </MenuLink>
                            </StyledMenuItem>
                            <StyledMenuItem data-id="privacy_policy" onClick={handleClose}>
                                <MenuLink
                                    href="https://www.melittaklinik.com/privacy/"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <MenuText>{t("navigation.help.privacy_policy")}</MenuText>
                                </MenuLink>
                            </StyledMenuItem>
                            <StyledMenuItem data-id="contact" onClick={handleClose}>
                                <MenuLink
                                    href="https://www.melittaklinik.com/impressum/"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <MenuText>{t("navigation.help.contact")}</MenuText>
                                </MenuLink>
                            </StyledMenuItem>
                            <StyledMenuItem data-id="site_notice" onClick={handleClose}>
                                <MenuLink
                                    href="https://www.melittaklinik.com/impressum/"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <MenuText>{t("navigation.help.site_notice")}</MenuText>
                                </MenuLink>
                            </StyledMenuItem>
                        </StyledMenu>
                    </NavigationSection>
                </StyledToolbar>
            </AppBar>
            <StyledToolbar />

            <AppBar color="inherit" position="fixed" style={{ bottom: 0, top: "auto" }}>
                <Divider />
                <StyledToolbar variant="dense">
                    <CEMark src="/ce.svg" alt="CE Mark" />
                    {(generalStore.locale === "de" || generalStore.locale === "it") && (
                        <Disclaimer>{t("gender_disclaimer")}</Disclaimer>
                    )}
                </StyledToolbar>
            </AppBar>
        </>
    );
});
